import React, { useEffect, useState, useRef } from 'react';
import CryptoJS from 'crypto-js';

// 生成随机颜色的函数，放在组件外部以确保可以在任何地方调用
const getRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  return `rgb(${red}, ${green}, ${blue})`;
};

const StarryCountdown = () => {
  const initialCountdownValue = 5; // 初始倒计时值
  const [countdownValue, setCountdownValue] = useState(initialCountdownValue);
  const [fontColor, setFontColor] = useState(getRandomColor()); // 初始化字体颜色
  const [componentKey, setComponentKey] = useState(0); // 用于强制重新渲染组件
  const canvasRef = useRef(null);
  const [decryptedMessage, setDecryptedMessage] = useState("");

  useEffect(() => {
    // 监听返回事件，并重置倒计时和解密消息
    const handlePopState = () => {
      setComponentKey(prevKey => prevKey + 1); // 改变key值，强制重新渲染组件
      setCountdownValue(initialCountdownValue); // 重置倒计时值
      setDecryptedMessage(""); // 重置解密信息
    };

    window.addEventListener("popstate", handlePopState);

    return () => window.removeEventListener("popstate", handlePopState);
  }, []); // 空依赖数组，确保只调用一次

  useEffect(() => {
    // 在组件加载时调用一次 fetchAndDecrypt
    fetchAndDecrypt();
  }, [componentKey]); // 每次组件重新渲染时重新调用解密函数

  useEffect(() => {
    // 倒计时逻辑
    if (countdownValue > 0) {
      const countdownTimeout = setTimeout(() => {
        setCountdownValue(countdownValue - 1);
      }, 1000);

      return () => clearTimeout(countdownTimeout);
    } else {
      // 倒计时结束，可以执行跳转
      window.location.href = decryptedMessage;
    }
  }, [countdownValue, decryptedMessage]); // 监听倒计时和解密消息

  useEffect(() => {
    // 初始化背景星空
    const createStarryBackground = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const numStars = 5000;
      for (let i = 0; i < numStars; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        const radius = Math.random() * 2;
        const opacity = Math.random();
        const color = getRandomColor();
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fillStyle = color; // 使用随机颜色
        ctx.fill();
      }
    };

    createStarryBackground();

    const handleResize = () => {
      createStarryBackground(); // 重新绘制背景星空
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [componentKey]); // 组件重新渲染时重新初始化背景

  const fetchAndDecrypt = async () => {
    try {
      const response = await fetch("/api/encrypt");
      const data = await response.json();

      // 解析密文和 IV
      const cipherText = CryptoJS.enc.Base64.parse(data.t1); 
      const iv = CryptoJS.enc.Base64.parse(data.t2); 

      // 使用固定密钥进行解密
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥

      // 解密操作
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
      const plainText = decrypted.toString(CryptoJS.enc.Utf8);

      setDecryptedMessage(plainText || "失败");
    } catch (error) {
      console.error("出错：", error);
      setDecryptedMessage("失败");
    }
  };

  return (
    <div key={componentKey} style={{ margin: 0, overflow: 'hidden', fontFamily: 'Arial, sans-serif' }}>
      <div style={countdownStyle33} >
      </div>
      <div id="countdown" style={countdownStyle}>
        {countdownValue > 0 ? `倒计时: ${countdownValue} 秒` : `${decryptedMessage}`} 
      </div>
      <div id="countdowto" style={countdowto}>
         即将进入
      </div>
      <div
        id="countdown2"
        style={{ ...countdownStyle2, color: fontColor }}
      >
        {decryptedMessage}
      </div>
      <canvas
        ref={canvasRef}
        id="dsaqwCanvas"
        onClick={() => window.location.reload()}
        style={canvasStyle}
      ></canvas>
      <button style={buttonStyle} onClick={() => window.location.reload()}>
        跳转不进去？ 点一下刷新域名
      </button>
    </div>
  );
};

// 样式定义
const countdownStyle = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  width: '500px',
  transform: 'translate(-50%, -100%)',
  fontSize: '36px', // 调整字体大小
  fontWeight: 'bold', // 设置加粗
  color: 'white',
  textAlign: 'center',
};
const countdowto = {
  position: 'absolute',
  top: '41%',
  left: '50%',
  width: '500px',
  transform: 'translate(-50%, -100%)',
  fontSize: '36px', // 调整字体大小
  fontWeight: 'bold', // 设置加粗
  color: 'white',
  textAlign: 'center',
};

const countdownStyle2 = {
  position: 'absolute',
  top: '46%',
  left: '50%',
  fontWeight: 'bold', // 设置加粗
  transform: 'translate(-50%, -100%)',
  fontSize: '26px',
  textAlign: 'center',
};

const canvasStyle = {
  display: 'block',
  width: '100vw',
  height: '100vh',
};

const buttonStyle = {
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  height: '40px',
  fontSize: '16px',
  cursor: 'pointer',
};

const countdownStyle33 = {
  position: 'absolute',
  top: '26%',
  width: '100%',
  height: '300px',
  background:'black',
  cursor: 'pointer',
};

export default StarryCountdown;
